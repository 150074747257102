import React, { lazy, Suspense } from "react"
import { graphql } from "gatsby"

import { PostProvider } from "components/post/postContext"

import SEO from "../components/seo"
import Header from "components/header"
import ImpactArea from "components/sections/impactArea"
import Footer from "components/footer"

const AllVibes = lazy(() => import("components/post/blocks/vibeBlock/allVibes"))

const VibesIndex = ({ data }) => {
  //const allPosts = data.allWpPost.edges
  //const pageData = data.allWpPage.edges[0].node

  return (
    <div>
      <PostProvider>
        <SEO
            lang="en-US"
            title={data.allWpPage.edges[0].node.seo.title}
            data={data.allWpPage.edges[0].node.seo}
        />
        <main>
            <Header />
            <ImpactArea data={data.allWpPage.edges[0].node} />

            <div className="post-body">
                <div className="vibe-container">
                  <Suspense fallback={<span>...</span>} >
                    <AllVibes />
                  </Suspense>
                </div>
            </div>
        </main>
        <Footer />
      </PostProvider>
    </div>
  )
}

export default VibesIndex

export const vibeQuery = graphql`
  query VibesQuery {

    allWpCity(sort: { order: ASC, fields: title }) {
      nodes {
        databaseId
        title
        slug
      }
    }
    allWpPage(filter: {slug: {in: "vibes"}}) {
      edges {
        node {
          id
          databaseId
          title
          date
          title
          seo {
            title
            opengraphAuthor
            focuskw
            canonical
            opengraphDescription
            metaRobotsNoindex
            metaRobotsNofollow
            metaDesc
            metaKeywords
            opengraphModifiedTime
            opengraphPublishedTime
            opengraphPublisher
            opengraphSiteName
            opengraphTitle
            opengraphType
            opengraphUrl
          }
          impactArea {
            textColor
            backgroundColor
            backgroundType
            backgroundImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    layout: CONSTRAINED,
                    placeholder: DOMINANT_COLOR
                  )
                }
              }
            }
            heading
            vibeset {
              ... on WpVibeset {
                id
                title
                databaseId
                vibesetDetails {
                  gradientImage {
                    mediaItemUrl
                  }
                  gradientVideo {
                    mediaItemUrl
                  }
                }
              }
            }
          }
          sections {
            fieldGroupName
            sections {
              ... on WpPage_Sections_Sections_SingCardsBlock {
                singCards {
                  fieldGroupName
                  heading
                  posts {
                    vibes
                    vibeQuery {
                      name
                      slug
                    }
                    type
                    title
                    subcategory
                    placeId
                    geoQuery {
                      latitude
                      longitude
                    }
                    fieldGroupName
                    distanceQuery
                    description
                    city
                    categoryQuery
                    numOfPlaces
                  }
                  vibemapSuggests
                  subheading
                  style
                }
              }
            }
          }
        }
      }
    }
  }
`
